import { FC, useCallback, useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";

// Converts Stripe's payment methods to what we use throughout the app
const mapPaymentMethodType: (type: string) => "cc" | "ach" = (type: string) => {
  switch (type) {
    case "card":
      return "cc";
    case "us_bank_account":
      return "ach";
    default:
      return "cc";
  }
};

const PaymentInput: FC<{
  className?: string;
  onPaymentMethodChange?: (paymentMethod: "cc" | "ach") => void;
}> = ({ className, onPaymentMethodChange }) => {
  const [previousPaymentMethod, setPreviousPaymentMethod] = useState<string>();

  const handleChange = useCallback(
    (event: StripePaymentElementChangeEvent) => {
      const currentPaymentMethod = mapPaymentMethodType(event.value.type);
      if (currentPaymentMethod !== previousPaymentMethod) {
        onPaymentMethodChange?.(currentPaymentMethod);
        setPreviousPaymentMethod(currentPaymentMethod);
      }
    },
    [onPaymentMethodChange, previousPaymentMethod]
  );

  return <PaymentElement className={className} onChange={handleChange} />;
};

export default PaymentInput;
